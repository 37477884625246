import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Params } from '@angular/router';

import { TAnnouncementsListData } from './announcementsListTypes';
import { INITIAL_PER_PAGE } from '@settings/constants';
import { getPaginationData } from '@utils/getPaginationHeaders';
import { TFormattedApiError } from '@utils/formattingApiError';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AnnouncementsListService {
  constructor(private http: HttpClient) {}

  #announcementsList = signal<TAnnouncementsListData>([]);
  announcementsList = computed(this.#announcementsList);

  #currentPage = signal<number>(0);
  currentPage = computed(this.#currentPage);

  #totalPage = signal<number>(0);
  totalPage = computed(this.#totalPage);

  #perPage = signal<number>(0);
  perPage = computed(this.#perPage);

  #totalCount = signal<number>(0);
  totalCount = computed(this.#totalCount);

  #loader = signal<boolean>(true);
  loader = computed(this.#loader);

  #infiniteLoader = signal<boolean>(false);
  infiniteLoader = computed(this.#infiniteLoader);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  loadAnnouncementsList({
    params,
    isInfinite,
    url,
  }: {
    params: Params;
    isInfinite?: boolean;
    url: 'announcements/main-page' | 'announcements' | 'announcements/map';
  }): void {
    if (this.#currentPage() >= this.#totalPage() && this.#currentPage() !== 0)
      return;

    if (isInfinite) {
      this.#infiniteLoader.set(true);
    } else {
      this.#loader.set(true);
    }

    this.#currentPage.set(this.#currentPage() + 1);

    const allParams: Params = {
      'per-page': INITIAL_PER_PAGE,
      page: this.#currentPage(),
      type_value: 'sell',
      ...params,
    };

    this.http
      .get<TAnnouncementsListData>(`${environment.getApiVersionUrl(url)}`, {
        // headers: {
        //   skipBearerAuthorization: 'true',
        // },
        params: allParams,
        transferCache: {
          includeHeaders: [
            'X-Pagination-Current-Page',
            'X-Pagination-Page-Count',
            'X-Pagination-Per-Page',
            'X-Pagination-Total-Count',
          ],
        },
        observe: 'response',
      })
      .subscribe({
        next: (response) => {
          const responsePagination =
            getPaginationData<TAnnouncementsListData>(response);

          this.#currentPage.set(responsePagination.currentPage);
          this.#totalPage.set(responsePagination.totalPage);
          this.#perPage.set(responsePagination.perPage);
          this.#totalCount.set(responsePagination.totalCount);

          this.#announcementsList.set([
            ...this.#announcementsList(),
            ...responsePagination.data,
          ]);
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);

          console.error(
            `${environment.getApiVersionUrl('announcements')}`,
            error,
          );
        },
        complete: () => {
          if (isInfinite) {
            this.#infiniteLoader.set(false);
          } else {
            this.#loader.set(false);
          }
        },
      });
  }

  resetAnnouncementData() {
    this.#announcementsList.set([]);
    this.#currentPage.set(0);
  }

  resetTotalCount() {
    this.#totalCount.set(0);
  }
}
